import React from 'react'
import Page from '../../components/page'
import { navigate } from 'gatsby';
import { AuthConsumer } from '../../components/auth/authContext';

const LogoutPage = () => (
  <Page authenticated={true}>
    <AuthConsumer>
      {({ logout }) => {
        logout();
        navigate('/');
        return (<h1>LOG OUT</h1>);
      }}
    </AuthConsumer>
  </Page>
);

export default LogoutPage
